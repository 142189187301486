import type {IFilterOption} from '../../types/galleryTypes';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {BASE_TICKS} from './constants';

export const generatePriceFilterOptions = (
  min: number,
  max: number,
  formatCurrency: ControllerFlowAPI['formatCurrency'],
  currency: string,
  conversionRateDecimal: number = 1
): IFilterOption[] => {
  const convertedMinPrice = Math.floor(min * conversionRateDecimal);
  const convertedMaxPrice = Math.ceil(max * conversionRateDecimal);
  const delta = convertedMaxPrice - convertedMinPrice;
  const ticksAmount = delta <= BASE_TICKS ? delta + 1 : BASE_TICKS; // delta+1 to include max as a tick
  const stepSize = Math.max(Math.round(delta / ticksAmount), 1);

  const ticks = [];
  for (let i = 0; i < ticksAmount - 1; i++) {
    ticks.push(Math.round(convertedMinPrice + stepSize * i));
  }
  ticks.push(convertedMaxPrice); // push max separately to avoid number larger than max

  return ticks.map((price) => {
    const originalCurrencyPrice = price / conversionRateDecimal;
    return {
      key: originalCurrencyPrice.toString(),
      value: formatCurrency({value: price, currency}) as string,
    };
  });
};
