import {ConversionRateResponse as ConversionRateResponseServer} from '@wix/ambassador-ecommerce-currency-converter-v1-currency-rate/types';
import type {IHttpClient} from '@wix/fe-essentials/http-client';
import {ReportError} from '../viewerScript/GalleryController';
import {conversionRate as getConversionRateApi} from '@wix/ambassador-ecommerce-currency-converter-v1-currency-rate/http';

export type ConversionRateResponse = {data: ConversionRateResponseServer};

export const getConversionRate = async (
  httpClient: IHttpClient,
  reportError: ReportError,
  currency: string,
  currentCurrency: string
): Promise<ConversionRateResponse> => {
  return httpClient.request(getConversionRateApi({from: currency, to: currentCurrency})).catch((err) => {
    reportError(err, false);
    return {data: {rate: {value: '1', decimalPlaces: 0}}};
  });
};
