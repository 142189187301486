/* istanbul ignore file */
import {createStylesParams, StyleParamType} from '@wix/tpa-settings';
import {baseStylesParams} from '../../styleParams/baseStylesParams';
import {IStylesParams, StylesParamKeys} from '../../styleParams/types';
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';
import {updatedStyleParamsOverrides} from '../../styleParams/updatedStyleParamsOverrides';
import {GridType} from '../../types/galleryTypes';

export const customCssVars: CustomCssVarsFn = (params: CustomCssVarsFnParams) => ({
  ...baseCustomCssVars(params),
});
const stylesParams: IStylesParams = {
  ...baseStylesParams,
  ...updatedStyleParamsOverrides,
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: () => GridType.MANUAL,
  },
  gallery_showCategoryHeaderImage: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoryHeaderName: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoryHeaderDescription: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoriesBreadcrumbs: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategories: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_showCategoriesProductsCounter: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  galleryMarginRow: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
