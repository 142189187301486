/* istanbul ignore file */
import {createStylesParams, StyleParamType, wixFontParam} from '@wix/tpa-settings';
import {baseStylesParams} from '../../styleParams/baseStylesParams';
import {IStylesParams, StylesParamKeys} from '../../styleParams/types';
import {GridType} from '../../types/galleryTypes';
import {AutoGridTemplateRepeatOptions, CategoryListStrategy} from '../../constants';
import {baseCustomCssVars, CustomCssVarsFnParams} from '../../baseCustomCssVars';

export const customCssVars = (params: CustomCssVarsFnParams) => {
  return {
    ...baseCustomCssVars(params),
  };
};

const stylesParams: IStylesParams = {
  ...baseStylesParams,
  galleryShowFilters: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showCategoriesTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_showFiltersTitle: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  galleryShowSort: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: () => GridType.AUTO,
  },
  gallery_categoryListStrategy: {
    type: StyleParamType.Number,
    getDefaultValue: () => CategoryListStrategy.AUTOMATICALLY,
  },
  gallery_filterOptionsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  showQuickView: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_addToCartButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  gallery_ribbonSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 4,
  },
  gallery_ribbonTopPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 4,
  },
  gallery_autoGridTemplateRepeatOption: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: AutoGridTemplateRepeatOptions.AUTO_FILL,
    }),
  },
  gallery_layoutVerticalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  gallery_layoutHorizontalPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
};

export default createStylesParams<StylesParamKeys>(stylesParams);
